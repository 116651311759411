import { observer } from 'mobx-react-lite'
import ConcertRegisterContent from './ConcertRegisterContent'

const ConcertRegisterB1 = observer((props: any) => {
   return (
      <ConcertRegisterContent type='b1' />
   )
})

export default ConcertRegisterB1
