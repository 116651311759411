import { noop } from 'lodash'
import { action } from 'mobx'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'

export class CheckinStore {


    @action
    async checkin(id: string, params = {}) {
        const result = await Request2.postWithToken(params, api.checkin.checkin + '/' + id)
    }

    @action
    async checkinConcept(id: string, isShowError = false) {
        const result = await Request2.postWithToken({}, api.checkin.checkinConcept + '/' + id, isShowError)
        return result
    }

    @action
    async createTicket(params: any = {}) {
        const result = await Request2.postWithToken(params, api.checkin.createTicket)
        return result
    }

}
