import { observer } from 'mobx-react-lite'
import ConcertRegisterContent from './ConcertRegisterContent'

const ConcertRegisterC = observer((props: any) => {
   return (
      <ConcertRegisterContent type='c' />
   )
})

export default ConcertRegisterC
