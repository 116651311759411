import { Route, Switch } from 'react-router-dom'
import NotFoundPage from '../404/NotFoundPage'
import DefaultAdminScreen from '../default/DefaultAdminScreen'
import { ROUTER_PATH } from './metaData'
import ProfileScreen from '../profile'
import IntroPage from '../Intro/Intro'
import InspectionManagement from '../inspection-management'
import CheckinManagement from '../checkin-management'
import CheckinMusicManagement from '../checkin-music-management'
import AccountManagement from '../account-management'
const AdminRouters = (props: any) => {
   return (
      <Switch>
         <Route exact path={`/`} component={DefaultAdminScreen} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.IntroPage}`} component={IntroPage} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.CheckinManagement}`} component={CheckinManagement} />
         <Route
            exact
            path={`${props.match.url}${ROUTER_PATH.CheckinMusicManagement}`}
            component={CheckinMusicManagement}
         />
         <Route exact path={`${props.match.url}${ROUTER_PATH.InspectionManagement}`} component={InspectionManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.AccountManagement}`} component={AccountManagement} />
         <Route exact path={`${props.match.url}${ROUTER_PATH.ProfileScreen}`} component={ProfileScreen} />
         <Route path="*" component={NotFoundPage} />
      </Switch>
   )
}

export default AdminRouters
