import { useEffect, useState } from 'react'
import { Input, Modal, Form, Checkbox, Button, Row, Col, Radio, Space } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { isEmpty } from 'lodash'
import useIsMobile from 'src/hook/hookDevice'
import styled from 'styled-components'
import { Loading } from 'src/common'

export const SurveyWrapper = styled.div`
   .ant-checkbox-wrapper {
      padding: 6px;
   }
   .question {
      margin-bottom: 10px;
      font-size: 16px;

      .question-title {
         font-weight: 600;
         margin-bottom: 5px;
      }
      .sub-question {
         list-style: none;
         margin-bottom: 10px;
         .sub-question-title {
            margin-bottom: 5px;
         }
      }
   }
`

// const { TextArea } = Input
const Survey = observer((props: any) => {
   const { t } = useTranslation()

   const { item, visible, onOk, onCancel, onlyView } = props
   const isMobile = useIsMobile()
   const RegisterStore = useStore('RegisterStore')
   const [form] = Form.useForm()
   const [isLoading, setIsLoading] = useState(false)
   const isHasDataForm = !isEmpty(item?.checkMotobike?.dataForm)
   console.log('item----asdasda', item)

   const [questions, setQuestions] = useState([
      {
         id: 1,
         type: null,
         question: 'Thông tin xe',
         subQuestion: [
            {
               id: 1,
               type: 'radio',
               title: 'Thương hiệu xe',
               options: ['Honda', 'Yamaha', 'Piaggio', 'Suzuki', 'Sym', 'Vespa', 'Vinfast', 'Khác'],
               answer: '',
               txtOther: ''
            },
            {
               id: 2,
               type: 'text',
               title: 'Dòng xe',
               options: [],
               answer: '',
               txtOther: ''
            }
         ],
         options: [],
         answer: '',
         txtOther: ''
      },
      {
         id: 2,
         type: 'radio',
         question: 'Lốp trước đang sử dụng',
         subQuestion: [],
         options: [
            'IRC/INOUE',
            'Casumia',
            'Chengshin',
            'Maxxis',
            'Yokohama',
            'Kenda',
            'DUNLOP',
            'Michelin',
            'DRC',
            'SRC',
            'Khác'
         ],
         answer: '',
         txtOther: ''
      },
      {
         id: 3,
         type: 'radio',
         question: 'Lốp sau đang sử dụng',
         subQuestion: [],
         options: [
            'IRC/INOUE',
            'Casumia',
            'Chengshin',
            'Maxxis',
            'Yokohama',
            'Kenda',
            'DUNLOP',
            'Michelin',
            'DRC',
            'SRC',
            'Khác'
         ],
         answer: '',
         txtOther: ''
      },
      {
         id: 4,
         type: null,
         question: 'Áp suất lốp',
         subQuestion: [
            {
               id: 1,
               type: 'text',
               title: 'Áp suất lốp trước',
               options: [],
               answer: '',
               txtOther: ''
            },
            {
               id: 2,
               type: 'text',
               title: 'Áp suất lốp sau',
               options: [],
               answer: '',
               txtOther: ''
            }
         ],
         options: [],
         answer: '',
         txtOther: ''
      },
      {
         id: 5,
         type: 'radio',
         question: 'Tình trạng lốp trước',
         subQuestion: [],
         options: ['Tốt', 'Xấu', 'Còn sử dụng được'],
         answer: '',
         txtOther: ''
      },
      {
         id: 6,
         type: 'radio',
         question: 'Tình trạng lốp sau',
         subQuestion: [],
         options: ['Tốt', 'Xấu', 'Còn sử dụng được'],
         answer: '',
         txtOther: ''
      },
      {
         id: 7,
         type: null,
         question: 'Kiểm tra má phanh',
         subQuestion: [
            {
               id: 1,
               type: 'radio',
               title: 'Má phanh trước',
               options: ['Tốt', 'Xấu', 'Còn sử dụng được'],
               answer: '',
               txtOther: ''
            },
            {
               id: 2,
               type: 'radio',
               title: 'Má phanh sau',
               options: ['Tốt', 'Xấu', 'Còn sử dụng được'],
               answer: '',
               txtOther: ''
            }
         ],
         options: [],
         answer: '',
         txtOther: ''
      },
      {
         id: 8,
         type: 'radio',
         question: 'Căn chỉnh garanti',
         subQuestion: [],
         options: ['Đã căn chỉnh', 'Khác'],
         answer: '',
         txtOther: ''
      },
      {
         id: 9,
         type: 'radio',
         question: 'Vệ sinh bugi',
         subQuestion: [],
         options: ['Đã chăm sóc', 'Khác'],
         answer: '',
         txtOther: ''
      },

      {
         id: 10,
         type: 'radio',
         question: 'Kiểm tra ắc quy - Hệ thống sạc?',
         subQuestion: [],
         options: ['Đã căn chỉnh', 'Khác'],
         answer: '',
         txtOther: ''
      },
      {
         id: 11,
         type: 'radio',
         question: 'Kiểm tra điện, đèn, còi, xi nhan,..',
         subQuestion: [],
         options: ['Đã chăm sóc', 'Khác'],
         answer: '',
         txtOther: ''
      },
      {
         id: 12,
         type: 'radio',
         question: 'Kiểm tra dầu nhớt',
         subQuestion: [],
         options: ['Đã chăm sóc', 'Khác'],
         answer: '',
         txtOther: ''
      },

      {
         id: 13,
         type: 'radio',
         question: 'Bôi mỡ, tăng nhông xích tải',
         subQuestion: [],
         options: ['Đã chăm sóc', 'Khác'],
         answer: '',
         txtOther: ''
      },
      {
         id: 14,
         type: 'radio',
         question: 'Siết chặt, tra mỡ ốc trục các loại',
         subQuestion: [],
         options: ['Đã chăm sóc', 'Khác'],
         answer: '',
         txtOther: ''
      }
   ])

   useEffect(() => {
      if (isHasDataForm) return setQuestions(item?.checkMotobike?.dataForm.survey)
   }, [])

   const qrCodeValue = JSON.stringify({
      id: item?.id,
      name: item?.name,
      phone: item?.phoneNumber,
      code: item?.ticket?.code
   })

   const onFinish = async () => {
      console.log('questions =>', questions)
      console.log('item =>', item)

      try {
         setIsLoading(false)
         const result = await RegisterStore.updateSurveyForm(item.id, { survey: questions })
         if (result) {
            onOk()
         }
      } catch (error) {
      } finally {
         setIsLoading(false)
      }
   }

   const handleUpdateAnswer = (id: number, newAnswer: string, isOther = false) => {
      setQuestions((prev) =>
         prev.map((question) =>
            question.id === id
               ? isOther
                  ? { ...question, txtOther: newAnswer }
                  : { ...question, answer: newAnswer }
               : question
         )
      )
   }

   const handleUpdateSubQuestionAnswer = (
      questionId: number,
      subQuestionId: number,
      newAnswer: string,
      isOther = false
   ) => {
      setQuestions((prevQuestions) =>
         prevQuestions?.map((question: any) =>
            question.id === questionId
               ? {
                    ...question,
                    subQuestion: question.subQuestion.map((subQ: any) =>
                       subQ?.id === subQuestionId
                          ? isOther
                             ? { ...subQ, txtOther: newAnswer }
                             : { ...subQ, answer: newAnswer }
                          : subQ
                    )
                 }
               : question
         )
      )
   }

   const renderContent = () => {
      return (
         <SurveyWrapper>
            <Loading isLoading={isLoading} />
            {questions?.map((q, index) => {
               return (
                  <div className="question" key={`question-${q.id}`}>
                     <p className="question-title">{`${index + 1}. ${q.question}`}</p>
                     {q.subQuestion?.length > 0 ? (
                        q.subQuestion?.map((sq: any) => (
                           <div className="sub-question" key={`question-${q.id}-sub-question-${sq.id}`}>
                              <p className="sub-question-title">{`${sq.title}:`}</p>

                              {sq.type === 'text' && (
                                 <Input
                                    value={sq.answer}
                                    onChange={(e) => handleUpdateSubQuestionAnswer(q.id, sq.id, e.target.value)}
                                 ></Input>
                              )}
                              {sq.type === 'checkbox' && (
                                 <Checkbox.Group
                                    value={sq.answer}
                                    onChange={(value) => handleUpdateSubQuestionAnswer(q.id, sq.id, value as any)}
                                    style={{ width: '100%' }}
                                 >
                                    <Row>
                                       {sq.options.map((option: any, index: number) => (
                                          <Col span={24} md={6} key={option}>
                                             <Checkbox value={option}>{option}</Checkbox>
                                          </Col>
                                       ))}
                                    </Row>
                                 </Checkbox.Group>
                              )}
                              {sq.type === 'radio' && (
                                 <Radio.Group
                                    style={{ width: '100%' }}
                                    value={sq.answer}
                                    onChange={(e) => handleUpdateSubQuestionAnswer(q.id, sq.id, e.target.value)}
                                 >
                                    <Space direction="vertical" style={{ width: '100%' }}>
                                       {sq.options.map((option: any, index: number) => (
                                          <Radio value={option} key={option}>
                                             {option}
                                          </Radio>
                                       ))}
                                       {sq.answer === 'Khác' && (
                                          <Input.TextArea
                                             value={sq.txtOther}
                                             onChange={(e) =>
                                                handleUpdateSubQuestionAnswer(q.id, sq.id, e.target.value, true)
                                             }
                                             style={{ width: '100%' }}
                                          />
                                       )}
                                    </Space>
                                 </Radio.Group>
                              )}
                           </div>
                        ))
                     ) : (
                        <>
                           {q.type === 'text' && (
                              <Input
                                 value={q.answer}
                                 onChange={(e) => handleUpdateAnswer(q.id, e.target.value)}
                              ></Input>
                           )}
                           {q.type === 'checkbox' && (
                              <Checkbox.Group
                                 style={{ width: '100%' }}
                                 defaultValue={[]}
                                 onChange={(value) => handleUpdateAnswer(q.id, value as any)}
                              >
                                 <Row>
                                    {q.options.map((option: any, index: number) => (
                                       <Col span={24} md={6} key={option}>
                                          <Checkbox value={option}>{option}</Checkbox>
                                       </Col>
                                    ))}
                                 </Row>
                              </Checkbox.Group>
                           )}
                           {q.type === 'radio' && (
                              <Radio.Group
                                 style={{ width: '100%' }}
                                 value={q.answer}
                                 onChange={(e) => handleUpdateAnswer(q.id, e.target.value)}
                              >
                                 <Space direction="vertical" style={{ width: '100%' }}>
                                    {q.options.map((option: any, index: number) => (
                                       <Radio value={option} key={option}>
                                          {option}
                                       </Radio>
                                    ))}
                                    {q.answer === 'Khác' && (
                                       <Input.TextArea
                                          value={q.txtOther}
                                          onChange={(e) => handleUpdateAnswer(q.id, e.target.value, true)}
                                          style={{ width: '100%' }}
                                       />
                                    )}
                                 </Space>
                              </Radio.Group>
                           )}
                        </>
                     )}
                  </div>
               )
            })}
         </SurveyWrapper>
      )
   }
   return (
      <Modal
         width={isMobile ? '100%' : '600px'}
         visible={visible}
         centered
         title={t(GLOBAL_CLIENT.detail)}
         okText={t(GLOBAL_CLIENT.edit)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         onOk={form.submit}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
               {isEmpty(item?.checkMotobike?.dataForm) && (
                  <Button type="primary" onClick={onFinish}>
                     Hoàn thành
                  </Button>
               )}
            </>
         ]}
      >
         {renderContent()}
      </Modal>
   )
})

export default Survey
