import { action } from 'mobx'
import { Request2 } from 'src/common/Request2'
import api from 'src/common/api'

export class RegisterStore {
   @action
   async concertRegister(params = {}) {
      const result = await Request2.post(params, api.register.concert)
      return result
   }

   @action
   async concertRegisterBrand(params = {}) {
      const result = await Request2.post(params, api.register.concertBrand)
      return result
   }

   @action
   async inspecRegister(params = {}) {
      const result = await Request2.post(params, api.register.inspec)
      return result
   }

   @action
   async updateSurveyForm(id:string, params = {}) {
      const result = await Request2.postWithToken(params, `${api.register.survey}/${id}`)
      return result
   }
}
