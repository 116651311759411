import { Card, Avatar, Button, Tag } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'
import { PlusOutlined, MinusOutlined } from '@ant-design/icons'
import moment from 'moment'

export const CartItemWrapper = styled.div``
const CardItem = ({ children, isCheckMoto, item, type = null, onClick, className, onDelete, onChange }: any) => {
   return (
      <CartItemWrapper>
         <Card className="w-full " bodyStyle={{ padding: '5px 10px' }}>
            <div className="gx-d-flex gx-align-items-center gx-justify-content-between" style={{ gap: '20px' }}>
               <div>
                  <span className="gx-d-block" style={{ fontSize: '16px', fontWeight: '500' }}>
                     Tên: {item.name}
                  </span>
                  <span className="gx-d-block">Sđt: {item.phoneNumber}</span>
                  {!!item?.licensePlate && <span className="gx-d-block">BKS: {item.licensePlate}</span>}

                  {isCheckMoto && item?.checkMotobike && (
                     <div>
                        <span className="gx-d-block">
                           Đăng ký : {`${moment(item?.checkMotobike?.dateRegister).format('DD/MM/YYYY')}`}
                        </span>
                        <span className="gx-d-block">Slot : {`${item?.checkMotobike?.slot}`}</span>
                     </div>
                  )}
               </div>
               {children}
               {/* <Button style={{ margin: '0' }} type="primary">
                  Checkin
               </Button> */}
               {/* <Button style={{ margin: '0' }} type="primary">
                        Kiểm tra xe
                     </Button> */}
               {/* <Button style={{ margin: '0' }} type="primary">
                        Kết quả
                     </Button> */}
            </div>
         </Card>
      </CartItemWrapper>
   )
}

export default CardItem
