import { observer } from 'mobx-react-lite'
import ConcertRegisterContent from './ConcertRegisterContent'

const ConcertRegisterB2 = observer((props: any) => {
   return (
      <ConcertRegisterContent type='b2' />
   )
})

export default ConcertRegisterB2
