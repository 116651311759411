import { useEffect, useState } from 'react'
import { Modal, Form, Button, notification } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'

import { useStore } from '../../hooks'
import useIsMobile from 'src/hook/hookDevice'

import { QrReader } from 'react-qr-reader'
import { showMessageError, showMessageSuccess } from 'src/helpers/functions'

const QRScanner = observer((props: any) => {
   const { t } = useTranslation()
   const [dataObject, setDataObject] = useState(null)
   const [qrCodeId, setQrCodeId] = useState(null)
   const { visible, onOk, onCancel, onCheckin, userSelected } = props
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const CheckinStore = useStore('CheckinStore')
   const [form] = Form.useForm()

   const isMobile = useIsMobile()

   useEffect(() => {
      if (qrCodeId) {
         checkinNow()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [qrCodeId])

   const checkinNow = async () => {
      console.log('now')
      if (qrCodeId) {
         //@ts-ignore
         const update = await CheckinStore.checkinConcept(qrCodeId as unknown as string, false)
         if (update) {
            //@ts-ignore
            // showMessageSuccess(`Tài khoản ${dataObject?.name} đã checkin thành công!`)
            notification.success({ message: `Checkin thành công!` })
            onCheckin()
            setTimeout(() => {
               setQrCodeId(null)
               setDataObject(null)
            }, 1000)
         } else {
            setTimeout(() => {
               setQrCodeId(null)
               setDataObject(null)
            }, 1000)
         }
         console.log('update', update, 'dataObject', qrCodeId)
      } else {
         notification.error({ message: 'Vé không hợp lệ!' })
         setTimeout(() => {
            setDataObject(null)
            setQrCodeId(null)
         }, 1000)
      }
   }

   const resetQR = () => {
      setDataObject(null)
      setQrCodeId(null)
   }

   return (
      <Modal
         width={isMobile ? '95%' : '68%'}
         visible={visible}
         centered
         title={'Quét QR checkin'}
         // okText={t(GLOBAL_CLIENT.txtCreate)}
         cancelText={t(GLOBAL_CLIENT.huy)}
         onCancel={onCancel}
         footer={[
            <>
               <Button key="back" onClick={onCancel}>
                  Đóng
               </Button>
            </>
         ]}
         // onOk={form.submit}
      >
         {/* {qrCodeId ? (
            <div>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>Khách hàng:</span>

                  <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.name || ''}</span>
               </div>
               <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>SDT:</span>

                  <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.phone || ''}</span>
               </div>
               <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 16 }}>
                  <span style={{ fontSize: 14, color: '#000', marginRight: 16 }}>Mã vé:</span>
                  <span style={{ fontSize: 14, color: '#000', fontWeight: 'bold' }}>{dataObject?.code || ''}</span>
               </div>
               <div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
                  <Button onClick={checkinNow} type="primary" className="gx-m-0">
                     Checkin
                  </Button>
                  <Button onClick={resetQR} type="ghost" className="gx-m-2">
                     Quét lại QR
                  </Button>
               </div>
            </div>
         ) : ( */}
         <QrReader
            onResult={(result, error) => {
               if (result) {
                  try {
                     // @ts-ignore
                     // const data1 = JSON.parse(result?.text)
                     // setDataObject(data1)
                     setQrCodeId(result?.text)
                  } catch (error) {
                     console.info(error)
                  }
               }
               // if (error) {
               //    alert("Lỗi quét mã QR:"+ error);
               //  }
               if (!!error) {
                  console.info(error)
               }
            }}
            constraints={{
               facingMode: 'environment', // Sử dụng camera sau
               // aspectRatio: 1, // Tỷ lệ khung hình
               // width: { ideal: 1280 }, // Độ phân giải mong muốn
               // height: { ideal: 1280 },
               //@ts-ignore
               // focusMode: 'continuous', // Chế độ lấy nét liên tục
               // zoom: { ideal: 1.2 } // Zoom vào giữa khung hình
            }}
            scanDelay={1000}
            //@ts-ignore
            style={{ width: '100%',  }}
         />
         {/* )} */}
      </Modal>
   )
})

export default QRScanner
