import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import QRCode from 'react-qr-code'
import { getZoneClass } from 'src/helpers/functions'
import { isArray, isEmpty } from 'lodash'
import moment from 'moment'
import { useState } from 'react'
import QRModal from './QRModal'

const DetailWrapper = styled.div`
   color: #fff;
   .title {
      text-align: center;
      color: #fff;
      font-size: 25px;
      font-weight: 700;
      margin-bottom: 30px;
   }
   .section-title {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.6;
      display: inline-block;
      margin-bottom: 10px;
   }
   .info-user {
      list-style: none;
      padding-left: 5px;
      font-size: 13px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
   }
   ul.info-user:last-of-type {
      margin-bottom: 15px;
   }

   ul.question,
   ul.sub-question {
      padding-left: 5px;
      list-style: none;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px !important;
      display: flex;
      justify-content: space-between;
      column-gap: 20px;
      &:has(.sub-question) {
         display: block;
      }

      li {
         margin-bottom: 10px !important;
      }
   }

   ul.sub-question {
      padding-left: 15px;
      font-weight: 500;
      li {
         font-size: 13px;
      }
   }
`
const DetailRegister = (props: any) => {
   const { t } = useTranslation()
   const { item, style, showTitle } = props
   const [isShow, setShow] = useState(false)
   // const qrCode = JSON.stringify({
   //    id: item?.id,
   //    name: item?.name,
   //    phone: item?.phoneNumber,
   //    code: item?.ticket?.code
   // })
   const qrCode = item?.id + ''
   return (
      <DetailWrapper style={style}>
         {/* <Button type="text" shape="circle" icon={<LeftOutlined style={{ color: '#fff', fontSize: '20px' }} />} /> */}
         {showTitle && <h1 className="title">Thông tin chi tiết</h1>}
         {item.ticket && (
            <>
               <div
                  className="gx-text-center"
                  style={{
                     marginBottom: 16,
                     borderRadius: 10,
                     overflow: 'hidden',
                     background: '#fff',
                     paddingTop: 24,
                     paddingBottom: 24
                  }}
                  onClick={() => {
                     setShow(true)
                  }}
               >
                  <QRCode size={256} style={{ height: 'auto', width: '86%' }} value={qrCode} viewBox={`0 0 256 256`} />
               </div>
               <div
                  style={{ textDecoration: 'underline', paddingBottom: 4, marginBottom: 16 }}
                  onClick={() => {
                     setShow(true)
                  }}
               >
                  Xem chi tiết
               </div>
            </>
         )}
         <span className="section-title">Thông tin</span>
         <div>
            <ul className="info-user">
               <li style={{ fontSize: '14px' }}>Họ và tên</li>
               <li>{item.name}</li>
            </ul>
            <ul className="info-user">
               <li style={{ fontSize: '14px' }}>Số điện thoại</li>
               <li>{item.phoneNumber}</li>
            </ul>
            {item.licensePlate && (
               <ul className="info-user">
                  <li style={{ fontSize: '14px' }}>Biển kiểm soát</li>
                  <li>{item.licensePlate}</li>
               </ul>
            )}
            {!isEmpty(item.ticket) && (
               <ul className="info-user">
                  <li style={{ fontSize: '14px' }}>Hạng vé</li>
                  <li>{!isEmpty(item.ticket) && `${getZoneClass(item.ticket.type + '')}`}</li>
               </ul>
            )}
            {!isEmpty(item.chiefCode) && (
               <ul className="info-user">
                  <li style={{ fontSize: '14px' }}>Mã quay thưởng</li>
                  <li>{!isEmpty(item.chiefCode) && item.phoneNumber}</li>
               </ul>
            )} 
            {!!item.checkin && (
               <ul className="info-user">
                  <li style={{ fontSize: '14px' }}>Check in</li>
                  <li>Đã check in</li>
               </ul>
            )}
            {!!item.ticket?.isCheckin && (
               <ul className="info-user">
                  <li style={{ fontSize: '14px' }}>Ca nhạc</li>
                  <li>Đã check in</li>
               </ul>
            )}
         </div>
         {!isEmpty(item.checkMotobike) && (
            <>
               <span className="section-title">Kiểm tra xe</span>
               {isEmpty(item.checkMotobike.dataForm?.survey) ? (
                  <>
                     <ul className="info-user">
                        <li style={{ fontSize: '14px' }}>Ngày đăng ký</li>
                        <li>{moment(item.checkMotobike?.dateRegister).format('DD/MM/YYYY')}</li>
                     </ul>
                     <ul className="info-user">
                        <li style={{ fontSize: '14px' }}>Khung giờ</li>
                        <li>{item.checkMotobike?.slot}</li>
                     </ul>
                  </>
               ) : (
                  (item.checkMotobike.dataForm?.survey || []).map((q: any, index: number) => {
                     return (
                        <ul className="question" key={`question-${q.id}`}>
                           <li className="question-title">{`${index + 1}. ${q.question}`}</li>
                           {q.subQuestion?.length > 0 ? (
                              q.subQuestion?.map((sq: any) => (
                                 <ul className="sub-question" key={`question-${q.id}-sub-question-${sq.id}`}>
                                    <li className="sub-question-title">{`${sq.title}`}</li>
                                    <li style={{ textAlign: 'right' }}>
                                       {sq.txtOther
                                          ? sq.txtOther
                                          : isArray(sq.answer)
                                          ? sq.answer.join(', ')
                                          : sq.answer}
                                    </li>
                                 </ul>
                              ))
                           ) : (
                              <li style={{ textAlign: 'right', fontSize: '13px' }}>
                                 {q.txtOther ? q.txtOther : isArray(q.answer) ? q.answer.join(', ') : q.answer}
                              </li>
                           )}
                        </ul>
                     )
                  })
               )}
            </>
         )}
         {isShow && <QRModal qrCode={qrCode} visible={isShow} onCancel={() => setShow(false)} />}
      </DetailWrapper>
   )
}

export default DetailRegister
