import { isEmpty } from 'lodash'
import { observable, action } from 'mobx'
import { PAGE_SIZE } from '../helpers/constants'
import api from '../common/api'
import { Request2 } from 'src/common/Request2'
import { responseDefault, responseDefault2 } from './store.constants'
import { STATUS_ACTIVITY } from '../helpers/constants'
// import { jobsFake } from './fakeData'
export const jobDetailInit = {
   id: 0,
   name: '',
   count: 1,
   designTypeId: undefined,
   productTypeId: undefined,
   note: '',
   images: [],
   tag: '',
   avatar: '',
   status: 0,
   creatorId: 0,
   designerId: 0,
   price: 0,
   actuallyReceived: 0,
   totalActuallyReceived: 0,
   totalPrice: 0,
   url: '',
   countReject: 0,
   startedAt: '',
   expiresAt: '',
   deletedAt: '',
   createdAt: '',
   updatedAt: '',
   isPriority: '',
   productType: {
      id: null,
      name: null,
      countJob: null,
      deletedAt: null,
      createdAt: null,
      updatedAt: null
   },
   designType: {
      id: null,
      name: '',
      countJob: 0,
      deletedAt: null,
      createdAt: null,
      updatedAt: null
   },
   creator: {
      name: '',
      avatar: '',
      email: ''
   },
   designer: {
      name: '',
      avatar: '',
      email: ''
   }
}
export class ProductStore {
   @observable jobs: any = { ...responseDefault }
   @observable products: any = { ...responseDefault }

   @observable jobsRecruiting: any = { ...responseDefault } //NEW: 0,
   @observable jobsProcessing: any = { ...responseDefault } //    DOING: 1,
   @observable jobsReview: any = { ...responseDefault } //    REVIEW: 2,
   @observable jobsEditing: any = { ...responseDefault } //    REJECT: 3,
   @observable jobsDone: any = { ...responseDefault } //    FINISH: 4,
   @observable jobsCount: any = {}
   @observable textSearch: string = ''
   @observable fieldFilter: any = {}
   @observable jobTotal: any = {}
   @observable jobDetail = { ...jobDetailInit }
   @observable questions: any = { ...responseDefault2 }
   @observable anwsers: any = { ...responseDefault2 }
   @observable questionsForUser: any = []
   @observable userInfo = {
      name: null,
      phone: null
   }
   @observable listQuestions: any = { ...responseDefault }
   @observable messageGameResult: any = null
   @observable imgGift: any = null
   @observable answerTrue: any = 0

   @action
   async action_updateJobPriority(index: number, status: boolean) {
      let rows1 = JSON.parse(JSON.stringify(this.jobs?.rows))
      console.log('row1', rows1, 'rows1[index]', rows1[index], 'rows1[index]?.isPriority', rows1[index]?.isPriority)
      // Set job thành job ưu tiên
      rows1[index] = { ...rows1[index], isPriority: status }
      this.jobs.rows = [...rows1]
      console.log('   rows1[index]', rows1[index])
   }
   @action
   async action_updateJobAssign(index: number, designer: any) {
      let rows1 = JSON.parse(JSON.stringify(this.jobs?.rows))
      console.log('row1', rows1, 'rows1[index]', rows1[index], 'rows1[index]?.isPriority', rows1[index]?.isPriority)
      // Gán designer cho job
      // Chuyển job mới thành Đang xử lý: status 0=>1
      rows1[index] = { ...rows1[index], designer, status: 1 }
      this.jobs.rows = [...rows1]
      console.log('   rows1[index]', rows1[index])
   }

   @action
   async action_removeJob(jobId: number) {
      let rows1 = JSON.parse(JSON.stringify(this.jobsRecruiting?.rows))
      // console.log('row1', rows1, 'rows1[index]', rows1[index], 'rows1[index]?.isPriority', rows1[index]?.isPriority);
      // Gán designer cho job
      // Chuyển job mới thành Đang xử lý: status 0=>1
      const index = rows1.findIndex((item: any) => jobId === item.id)
      if (index > -1) {
         rows1?.splice(index, 1)
      }
      // rows1[index] = { ...rows1[index], designer, status: 1 }
      this.jobsRecruiting.rows = [...rows1]
      console.log('   rows1[index]', rows1[index])
   }
   @action
   async action_setTextSearch(text: string) {
      this.textSearch = text
   }

   @action
   async action_setFieldFilter(data: any) {
      this.fieldFilter = { ...data }
   }

   @action
   async getJobTotal(status: string) {
      const result = await Request2.getWithToken(api.job.total, { status })
      // console.log('getJobTotal', result);
      if (!isEmpty(result)) {
         this.jobTotal = result
      }
   }

   @action
   async getJobList(params: any = {}, history?: any) {
      let param = { ...params }
      if (param?.isPriority) {
         param.isPriority = true
      } else {
         delete param['isPriority']
      }
      const result = await Request2.getWithToken(api.job.getList, { ...param, ...{ limit: PAGE_SIZE } })
      if (!isEmpty(result)) {
         if (params?.status === STATUS_ACTIVITY.NEW) {
            if (params?.offset !== 0 && params?.offset !== this.jobsRecruiting.offset) {
               this.jobsRecruiting = { ...result, rows: this.jobsRecruiting?.rows.concat(result?.rows) }
            } else {
               this.jobsRecruiting = result
            }
         }
         if (params?.status === STATUS_ACTIVITY.DOING) {
            if (params?.offset !== 0 && params?.offset !== this.jobsProcessing.offset) {
               this.jobsProcessing = { ...result, rows: this.jobsProcessing?.rows.concat(result?.rows) }
            } else {
               this.jobsProcessing = result
            }
         }
         if (params?.status === STATUS_ACTIVITY.REVIEW) {
            if (params?.offset !== 0 && params?.offset !== this.jobsReview.offset) {
               this.jobsReview = { ...result, rows: this.jobsReview?.rows.concat(result?.rows) }
            } else {
               this.jobsReview = result
            }
         }
         if (params?.status === STATUS_ACTIVITY.REJECT) {
            if (params?.offset !== 0 && params?.offset !== this.jobsEditing.offset) {
               this.jobsEditing = { ...result, rows: this.jobsEditing?.rows.concat(result?.rows) }
            } else {
               this.jobsEditing = result
            }
         }
         if (params?.status === STATUS_ACTIVITY.FINISH) {
            if (params?.offset !== 0 && params?.offset !== this.jobsDone.offset) {
               this.jobsDone = { ...result, rows: this.jobsDone?.rows.concat(result?.rows) }
            } else {
               this.jobsDone = result
            }
         }
         // if (isUndefined(params?.status) || isNull(params?.status)) this.jobs = result
      }
   }
   @action
   async getQuestions(params: any = {}) {
      let param = { ...params }
      if (param?.title) {
      } else {
         delete param['title']
      }
      const result = await Request2.getWithToken(api.questions.getList, { ...param, ...{ limit: PAGE_SIZE } })
      if (!isEmpty(result)) {
         this.questions = result
      } else {
         this.questions = { ...responseDefault2 }
      }
   }
   @action
   async getAnwsers(params: any = {}) {
      let param = { ...params }
      if (param?.name) {
      } else {
         delete param['name']
      }
      const result = await Request2.getWithToken(api.questions.getListAnwser, { ...param, ...{ limit: PAGE_SIZE } })

      if (!isEmpty(result)) {
         this.anwsers = result
      } else {
         this.anwsers = { ...responseDefault2 }
      }
   }

   @action
   async answerQuestion(params: any = {}) {
      const result = await Request2.post(params, api.questions.anwser)
      if (!isEmpty(result)) {
         this.messageGameResult = result?.data?.message
         this.imgGift = result?.data?.img
         // this.imgGift = "static/20240919/2024091903253090.jpeg"

         this.answerTrue = result.data?.score
      } else {
         this.messageGameResult = null
         this.imgGift = null
         this.answerTrue = 0
      }
   }

   @action
   async getQuestionsForUser() {
      const result = await Request2.get(api.questions.listQuestionForUser)
      console.log('result', result)

      if (!isEmpty(result)) {
         this.questionsForUser = result
      } else {
         this.questionsForUser = []
      }
   }

   @action
   async createQuestion(params: any = {}) {
      const result = await Request2.postWithToken(params, api.questions.create)
      return result
   }
   @action
   async updateQuestion(params: any = {}, id: string | number) {
      const result = await Request2.patchWithToken(params, api.questions.edit + '/' + id)
      return result
   }
   @action
   async deleteQuestions(id: string | number) {
      const result = await Request2.deleteWithToken({}, api.questions.delete + '/' + id)
      return result
   }
   @action
   async getProducts(params: any = {}) {
      let param = { ...params }
      // if (param?.isPriority) {
      //    param.isPriority = true
      // } else {
      //    delete param['isPriority']
      // }
      const result = await Request2.getWithToken(api.products.getList, { ...param, ...{ limit: PAGE_SIZE } })

      if (!isEmpty(result)) {
         this.products = result
      }
   }

   @action
   async action__cardBtn(api: string, params: any = {}) {
      const result = await Request2.postWithToken({}, api)
      return result
   }
   @action
   async createProduct(params: any = {}) {
      const result = await Request2.patchWithToken(params, api.products.create)
      return result
   }
   @action
   async updateProduct(params: any = {}, id: string | number) {
      const result = await Request2.postWithToken(params, api.products.edit + '/' + id)
      return result
   }

   @action
   async deleteProduct(id: string | number) {
      const result = await Request2.deleteWithToken({}, api.products.delete + '/' + id)
      return result
   }

   @action setJobDetailImages = (images: any) => {
      this.jobDetail.images = images
   }

   @action
   async cancelProduct(id: string | number) {
      const result = await Request2.postWithToken({}, api.job.cancel + '/' + id)
      return result
   }
   @action
   async updateJob(params: any = {}, id: string | number) {
      const result = await Request2.postWithToken(params, api.job.update + '/' + id)
      return result
   }

   @action
   async deleteJob(id: string | number) {
      const result = await Request2.postWithToken({}, api.job.delete + '/' + id)
      return result
   }

   @action
   async cancelJob(id: string | number) {
      const result = await Request2.postWithToken({}, api.job.cancel + '/' + id)
      return result
   }

   @action
   async getJobCount() {
      const result = await Request2.getWithToken(api.job.count)
      if (result) this.jobsCount = result
      return result
   }

   @action
   async resetJobCount(type: string) {
      this.jobsCount[type] = 0
   }

   @action
   async receiveNewJobs() {
      this.jobsCount = { ...this.jobsCount, new: this.jobsCount?.new + 1 || 0 }
   }

   @action
   async isAbleToReceiveJobs() {
      const result = await Request2.getWithToken(api.job.isReceive)
      return result
   }

   @action
   resetJobs() {
      this.jobs = { ...responseDefault }
      this.jobsRecruiting = { ...responseDefault }
      this.jobsProcessing = { ...responseDefault }
      this.jobsReview = { ...responseDefault }
      this.jobsEditing = { ...responseDefault }
      this.jobsDone = { ...responseDefault }
   }

   @action
   setUserInfo(info: any) {
      this.userInfo.name = info.name
      this.userInfo.phone = info.phone
   }

   @action
   async setAnswer(answer: string, questionIndex: number) {
      if (this.questionsForUser.length > 0 && this.questionsForUser[questionIndex]) {

         this.questionsForUser[questionIndex].answer = answer
      }
   }
}
