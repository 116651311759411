import { useEffect, useState } from 'react'
import { Table, Space, Button, Pagination, Input, Col, Row, Divider, Select } from 'antd'
import { debounce, isEmpty, size, trim } from 'lodash'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { PAGE_SIZE, POSITION_INFO, getPage } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../common'
import Detail from 'src/screens/authenticate/UserDetail'
import useWindowDimensions from '../../common/useWindowDimensions'
import useModal from 'src/helpers/modal/useModal'
import { CheckinWrapper } from '../authenticate/checkin'
import CardItem from 'src/common/components/CardCheckins/item'
import useIsMobile from 'src/hook/hookDevice'
const CheckinManagement = observer((props: any) => {
   const { t } = useTranslation()
   const UserStore = useStore('UserStore')
   const CheckinStore = useStore('CheckinStore')

   const [textSearch, set_textSearch] = useState('')
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const { height, width } = useWindowDimensions()

   const [visibleDetail, setVisibleDetail] = useState(false)
   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [item, setItem] = useState(null)
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const isMobile = useIsMobile()
   const { rows, offset, count } = UserStore.listEmployees

   useEffect(() => {
      if (size(trim(textSearch)) > 0) {
         getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(0, { ...fieldFilter })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [textSearch, fieldFilter])

   const getListData = async (currentPage: number, param?: any) => {
      try {
         // setIsLoading(true)
         const offset = getPage(currentPage)
         const params = { offset, ...param, type: 0 }
         // console.log('params', params)

         await UserStore.getEmployees(params)
      } catch (error) {
         // console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   const onChange = (currentPage: number) => {
      if (size(trim(textSearch)) > 0) {
         getListData(currentPage - 1, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
      } else {
         getListData(currentPage - 1, { ...fieldFilter })
      }
   }

   const handleChangeTextSearch = (value: any) => {
      console.log('value', value)

      set_textSearch(value)
   }
   const handleChangeTextSearchChange = (event: any) => {
      set_textSearch(event?.target?.value)
   }
   const clearDetailInfo = () => {
      setItem(null)
   }

   const setDetailInfo = (value: any) => {
      setItem(value)
   }

   const showDetail = (value: any) => {
      setDetailInfo(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

      setTimeout(() => {
         setVisibleDetail(true)
      }, 500)
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      clearDetailInfo()
   }
   const onCheckin = async (id: string) => {
      try {
         await CheckinStore.checkin(id)
         if (size(trim(textSearch)) > 0) {
            getListData(0, { ...fieldFilter, ...{ keyword: trim(textSearch) } })
         } else {
            getListData(0, { ...fieldFilter })
         }
         hideDetail()
      } catch (error) {}
   }

   const columns = [
      // {
      //    title: t(GLOBAL_CLIENT.id),
      //    dataIndex: 'id',
      //    key: 'id',
      //    align: 'center' as 'center',
      //    width: 50
      //    // render: (value: string, record: any, index: number) => (page - 1) * PAGE_SIZE + index + 1
      // },
      {
         title: t(GLOBAL_CLIENT.fullname),
         dataIndex: 'name',
         key: 'name',
         width: 80,
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
         // fixed: 'left',
      },
      {
         title: t(GLOBAL_CLIENT.phone),
         dataIndex: 'phoneNumber',
         width: 100,
         key: 'phoneNumber',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: t('Biển kiểm soát'),
         dataIndex: 'licensePlate',
         width: 100,
         key: 'licensePlate',
         render: (value: any) => {
            return <span className="txtMax2Line">{value}</span>
         }
      },
      {
         title: 'Mã quay thưởng',
         dataIndex: 'chiefCode',
         width: 100,
         key: 'chiefCode',
         render: (value: any, record: any) => {
            return <span className="txtMax2Line">{record?.chiefCode?.code}</span>
         }
      },
      {
         title: 'Action',
         key: 'action',
         width: 80,
         align: 'center' as 'center',
         fixed: 'right' as 'right',
         render: (record: any) => {
            return (
               <span className="txtMax2Line">
                  <span onClick={() => showDetail(record)} className="gx-link ">
                     {'Chi tiết'}
                  </span>
                  {!!!record.checkin && (
                     <>
                        <Divider type="vertical" />
                        <span className="gx-link" onClick={() => onCheckin(record.id)}>
                           {'Checkin'}
                        </span>
                     </>
                  )}
               </span>
            )
         }
      }
   ]

   const renderPagination = () => {
      if (isEmpty(rows)) {
         return null
      }

      return (
         <div className="containerPagination">
            <Pagination
               onChange={onChange}
               pageSize={PAGE_SIZE}
               current={offset / PAGE_SIZE + 1}
               showSizeChanger={false}
               total={count}
            />
         </div>
      )
   }

   const handleChangeRole = (value: any) => {
      console.log('value', value)
      if (!value) {
         let x = { ...fieldFilter }
         delete x['filter']
         setFieldFilter(x)
      } else {
         setFieldFilter({ ...fieldFilter, ...{ filter: value } })
      }
   }

   return (
      <>
         <Loading isLoading={isLoading} />
         <Row>
            <Col span={24}>
               <Row className="row-container-head">
                  <Col xs={14} md={8}>
                     <Input.Search
                        onSearch={handleChangeTextSearch}
                        onChange={debounce(handleChangeTextSearchChange, 500)}
                        placeholder={t(GLOBAL_CLIENT.search)}
                        allowClear
                     />
                  </Col>
                  <Col xs={10} md={4}>
                     <Select
                        value={fieldFilter.fulfillmentId}
                        placeholder="Tất cả"
                        onChange={handleChangeRole}
                        style={{ minWidth: 100 }}
                     >
                        <Select.Option key={''} value={''}>
                           {'Tất cả'}
                        </Select.Option>
                        <Select.Option key={'Check'} value={'Check'}>
                           {'Kiểm tra xe'}
                        </Select.Option>
                        <Select.Option key={'Concert'} value={'Concert'}>
                           {'Concert'}
                        </Select.Option>
                     </Select>
                  </Col>
               </Row>

               <Space
                  className="total-items"
                  style={{ width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
               >
                  <span>
                     {t(GLOBAL_CLIENT.totalItems)}: {count}
                  </span>
                  {/* <div>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={true}
                        onChange={(e) => onChangeStatus('active', e)}
                     >
                        {'Hoạt động'}
                     </Checkbox>
                     <Checkbox
                        style={{ paddingLeft: 4, paddingRight: 4 }}
                        defaultChecked={true}
                        onChange={(e) => onChangeStatus('inactive', e)}
                     >
                        {'Ngừng hoạt động'}
                     </Checkbox>
                  </div> */}
               </Space>
               {isMobile ? (
                  <CheckinWrapper style={{}}>
                     {rows.map((row: any) => {
                        return (
                           <CardItem item={row} key={row.id}>
                              <div className="gx-flex-column" style={{ gap: '5px' }}>
                                 <Button onClick={() => showDetail?.(row)} type="primary" className="gx-m-0">
                                    Chi tiết
                                 </Button>
                                 {!!!row.checkin && (
                                    <Button type="primary" className="gx-m-0" onClick={() => onCheckin(row.id)}>
                                       Checkin
                                    </Button>
                                 )}
                              </div>
                           </CardItem>
                        )
                     })}
                  </CheckinWrapper>
               ) : (
                  <>
                     <Table
                        scroll={{ x: 1500, y: height - 280 }}
                        columns={columns}
                        dataSource={rows}
                        rowKey="id"
                        pagination={false}
                     />
                  </>
               )}
               {renderPagination()}
            </Col>
         </Row>
         {visibleDetail && item && (
            <Detail visible={visibleDetail} item={item} onCancel={hideDetail} onCheckin={onCheckin} />
         )}
      </>
   )
})

export default CheckinManagement
