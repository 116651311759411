import { Input, Form, Button, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { rule } from '../../helpers/validateRule'
import { PhoneOutlined, UserOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import moment from 'moment'
import { isEmpty } from 'lodash'
import Images from 'src/common/Images'
import DetailRegister from 'src/common/components/DetailRegister'
import { normalizeText } from 'src/helpers/functions'

export const RegisterWrapper = styled.div`
   height: 100dvh;
   padding: 25px 35px;
   background-image: url(${(props) => props.theme.bg_img.bgNoLogo});
   background-size: cover; /* Ensures the image covers the entire area */
   background-position: center; /* Centers the image */
   background-repeat: no-repeat; /* Prevents tiling */
   background-attachment: fixed;
   overflow: hidden;
   .ant-btn > .ant-btn-loading-icon .anticon {
      margin-right: 10px;
      padding: 0;
   }
   .ant-form-item-label > label {
      color: #fff;
      height: 27px;
      font-size: 18px;
      font-weight: 500;
   }

   .success {
      width: 100%;
      position: absolute;
      top: 40%;
      left: 50%;
      transform: translate(-50%, -70%);
   }
   label[title='Thời gian']::before {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
   }
   .login-content__body {
      max-height: calc(100dvh - 50px);
      overflow-y: scroll;
      // hide scroll bar
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
         display: none; /* Chrome, Safari, and Opera */
      }
      .login-content__form {
         color: #fff;
         .form__title {
            font-size: 24px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 15px;
         }
         .time-left {
            display: inline-block;
            width: calc(50% - 10px);
         }
         .time-right {
            display: inline-block;
            width: calc(50% - 10px);
            margin-left: 20px !important;
         }
         .action {
            text-align: center;
            width: 100%;
            margin: 20px 0;
         }
      }
   }

   @media (min-width: 390px) {
      .login-content__form {
         .action {
            margin-top: 60px;
         }
      }
   }

   @media (max-width: 390px) {
      padding: 25px;
   }
`
const VehicleRegister = observer((props: any) => {
   const RegisterStore = useStore('RegisterStore')
   const { t } = useTranslation()
   const [isLoading, setIsLoading] = useState(false)
   const { history } = props
   const [form] = Form.useForm()
   const [detail, setDetail] = useState({})
   const [block, setBlock] = useState<any>([])
   // useEffect(() => {
   //    handleChangeSession('08:00-11:00')
   // }, [])
   const onFinish = async (values: any) => {
      const formData = { ...values }
      // delete formData.session
      console.log(form.getFieldsValue())

      try {
         setIsLoading(true)
         const name = normalizeText(values?.name)
         const result = await RegisterStore.inspecRegister({ ...formData, name })
         if (!isEmpty(result)) {
            console.log('ressssssssss', result.data)

            setDetail(result.data)
         }
      } catch (error) {
         console.log(error)
      } finally {
         setIsLoading(false)
      }
   }

   const handleChange = (value: any) => {
      console.log(`selected ${value}`)
   }

   const handleChangeSession = (value: any) => {
      console.log(`selehandleChangeSessioncted ${value.split('-')}`)
      // const blockTemp = generateTimeBlocks(value.split('-')[0], value.split('-')[1], 30).map((v) => {
      //    return {
      //       value: v,
      //       label: v
      //    }
      // })
      // setBlock(blockTemp as any)
   }

   function generateTimeBlocks(startTime: any, endTime: any, intervalMinutes: any) {
      const blocks = []

      // Initialize Moment.js with start time
      let currentTime = moment(startTime, 'HH:mm')

      // Loop until currentTime exceeds endTime
      while (currentTime.isBefore(moment(endTime, 'HH:mm').add(1, 'minute'))) {
         // Calculate end time of the current block
         let endBlock = moment(currentTime).add(intervalMinutes, 'minutes')

         // Format and push the time block (start time - end time)
         blocks.push(`${currentTime.format('HH:mm')} - ${endBlock.format('HH:mm')}`)

         // Move to the next block
         currentTime.add(intervalMinutes, 'minutes')
      }

      return blocks
   }

   const startTime = '08:00' // 8:00 AM
   const endTime = '11:00' // 11:00 AM
   const intervalMinutes = 30 // 30-minute blocks

   const timeBlocks = generateTimeBlocks(startTime, endTime, intervalMinutes)
   const isHide = parseFloat(moment().format('YYYYMMDD') + '') >= 20241226
   console.log(
      'isHide',
      isHide,
      `parseFloat(moment().format('YYYYMMDD') + '')`,
      parseFloat(moment().format('YYYYMMDD') + '')
   )
   return (
      <RegisterWrapper style={{}}>
         <div className="login-content__body">
            {!isEmpty(detail) ? (
               <DetailRegister item={detail} showTitle />
            ) : (
               <div className="login-content__form">
                  <div className="logo">
                     <img src={Images.logo_event} className="img-responsive" alt="Logo" />
                  </div>
                  <div className="form__title">{'Đăng ký kiểm tra xe'}</div>
                  <Form
                     form={form}
                     name="basic"
                     className="gx-signin-form gx-form-row0 login-form__content"
                     onFinish={onFinish}
                  >
                     <Form.Item label="Họ và tên" rules={[rule.requiredName]} name="name" className="gx-mb-3 title">
                        <Input placeholder={'Họ và tên'} prefix={<UserOutlined />} maxLength={100} />
                     </Form.Item>
                     <Form.Item
                        label="Số điện thoại"
                        rules={[rule.phoneRule, rule.requiredPhone]}
                        name="phoneNumber"
                        className="gx-mb-3"
                     >
                        <Input type="number" placeholder={'Số điện thoại'} prefix={<PhoneOutlined />} maxLength={30} />
                     </Form.Item>
                     <Form.Item
                        label="Biển số xe"
                        rules={[rule.requiredLicensePlate]}
                        name="licensePlate"
                        className="gx-mb-3"
                     >
                        <Input placeholder={'Biển số xe'} prefix={<PhoneOutlined />} maxLength={30} />
                     </Form.Item>
                     {!isHide && (
                        <Form.Item
                           label="Ngày kiểm tra"
                           initialValue={'26/12/2024'}
                           rules={[{ required: true }]}
                           name="dateRegister"
                           className="gx-mb-3"
                        >
                           <Select
                              className="m-full-width"
                              options={[
                                 {
                                    value: '26/12/2024',
                                    label: '26/12/2024'
                                 },
                                 {
                                    value: '27/12/2024',
                                    label: '27/12/2024'
                                 }
                              ]}
                           />
                        </Form.Item>
                     )}
                     {!isHide && (
                        <Form.Item rules={[{ required: true }]} label="Thời gian" style={{ marginBottom: 0 }}>
                           <Form.Item name="slot" initialValue={'Buổi sáng'} rules={[{ required: true }]}>
                              <Select
                                 className="m-full-width"
                                 // onChange={handleChangeSession}
                                 options={[
                                    {
                                       value: 'Buổi sáng',
                                       label: 'Buổi sáng'
                                    },
                                    {
                                       value: 'Buổi chiều',
                                       label: 'Buổi chiều'
                                    }
                                 ]}
                              />
                           </Form.Item>
                           {/* <Form.Item name="slot" rules={[rule.requiredPeriod]} className="time-right">
                           <Select className="m-full-width" onChange={handleChange} options={block} />
                        </Form.Item> */}
                        </Form.Item>
                     )}

                     <Form.Item className="action">
                        <Button
                           loading={isLoading}
                           htmlType="submit"
                           style={{
                              width: '80%',
                              height: '46px',
                              fontSize: '16px',
                              color: '#fff',
                              fontWeight: 'bold',
                              textTransform: 'uppercase',
                              backgroundColor: '#00C7FF',
                              borderColor: '#00C7FF',
                              borderRadius: '8px',
                              marginBottom: '30px'
                           }}
                        >
                           {'Đăng ký'}
                        </Button>
                     </Form.Item>
                  </Form>
               </div>
            )}
         </div>
      </RegisterWrapper>
   )
})

export default VehicleRegister
